export const HSRApoHisto = [
  {
    slug: 'acheron',
    score: 3287,
    usage: 28.45,
    role: 'dps'
  },
  {
    slug: 'argenti',
    score: 3305,
    usage: 0.67,
    role: 'dps'
  },
  {
    slug: 'arlan',
    score: 3000,
    usage: 0.01,
    role: 'dps'
  },
  {
    slug: 'asta',
    score: 3280,
    usage: 1.21,
    role: 'amplifier'
  },
  {
    slug: 'aventurine',
    score: 3463,
    usage: 42.07,
    role: 'sustain'
  },
  {
    slug: 'bailu',
    score: 3337,
    usage: 1.35,
    role: 'sustain'
  },
  {
    slug: 'black-swan',
    score: 3242,
    usage: 12.18,
    role: 'specialist'
  },
  {
    slug: 'blade',
    score: 3204,
    usage: 2.88,
    role: 'dps'
  },
  {
    slug: 'boothill',
    score: 3429,
    usage: 7.09,
    role: 'specialist'
  },
  {
    slug: 'bronya',
    score: 3376,
    usage: 15.23,
    role: 'amplifier'
  },
  {
    slug: 'clara',
    score: 3234,
    usage: 3.84,
    role: 'dps'
  },
  {
    slug: 'dan-heng',
    score: 3000,
    usage: 0.03,
    role: 'dps'
  },
  {
    slug: 'dr-ratio',
    score: 3340,
    usage: 14.68,
    role: 'dps'
  },
  {
    slug: 'feixiao',
    score: 3535,
    usage: 56.79,
    role: 'dps'
  },
  {
    slug: 'firefly',
    score: 3493,
    usage: 63.55,
    role: 'specialist'
  },
  {
    slug: 'fu-xuan',
    score: 3335,
    usage: 29.7,
    role: 'sustain'
  },
  {
    slug: 'gallagher',
    score: 3476,
    usage: 69.22,
    role: 'sustain'
  },
  {
    slug: 'gepard',
    score: 3339,
    usage: 1.68,
    role: 'sustain'
  },
  {
    slug: 'guinaifen',
    score: 3244,
    usage: 1.7,
    role: 'amplifier'
  },
  {
    slug: 'hanya',
    score: 3272,
    usage: 0.48,
    role: 'amplifier'
  },
  {
    slug: 'herta',
    score: 3214,
    usage: 1.02,
    role: 'dps'
  },
  {
    slug: 'himeko',
    score: 3314,
    usage: 5.49,
    role: 'dps'
  },
  {
    slug: 'hook',
    score: 3000,
    usage: 0.04,
    role: 'dps'
  },
  {
    slug: 'huohuo',
    score: 3362,
    usage: 23.22,
    role: 'sustain'
  },
  {
    slug: 'imbibitor-lunae',
    score: 3202,
    usage: 6.35,
    role: 'dps'
  },
  {
    slug: 'jade',
    score: 3290,
    usage: 2.51,
    role: 'specialist'
  },
  {
    slug: 'jiaoqiu',
    score: 3358,
    usage: 18.5,
    role: 'amplifier'
  },
  {
    slug: 'jing-yuan',
    score: 3170,
    usage: 1.42,
    role: 'dps'
  },
  {
    slug: 'jingliu',
    score: 3304,
    usage: 7.59,
    role: 'dps'
  },
  {
    slug: 'kafka',
    score: 3254,
    usage: 11.3,
    role: 'specialist'
  },
  {
    slug: 'lingsha',
    score: 3546,
    usage: 36.16,
    role: 'sustain',
    new: true
  },
  {
    slug: 'luka',
    score: 3311,
    usage: 0.2,
    role: 'specialist'
  },
  {
    slug: 'luocha',
    score: 3351,
    usage: 12.68,
    role: 'sustain'
  },
  {
    slug: 'lynx',
    score: 3314,
    usage: 1.31,
    role: 'sustain'
  },
  {
    slug: 'march-7th',
    score: 3000,
    usage: 0.07,
    role: 'sustain'
  },
  {
    slug: 'march-7th-swordmaster',
    score: 3394,
    usage: 23.7,
    role: 'specialist'
  },
  {
    slug: 'misha',
    score: 3000,
    usage: 0.08,
    role: 'dps'
  },
  {
    slug: 'moze',
    score: 3444,
    usage: 4.03,
    role: 'specialist'
  },
  {
    slug: 'natasha',
    score: 3326,
    usage: 0.18,
    role: 'sustain'
  },
  {
    slug: 'pela',
    score: 3299,
    usage: 14.57,
    role: 'amplifier'
  },
  {
    slug: 'qingque',
    score: 3067,
    usage: 0.37,
    role: 'dps'
  },
  {
    slug: 'rappa',
    score: 3523,
    usage: 15.7,
    role: 'specialist',
    new: true
  },
  {
    slug: 'robin',
    score: 3460,
    usage: 60.64,
    role: 'amplifier'
  },
  {
    slug: 'ruan-mei',
    score: 3482,
    usage: 88.5,
    role: 'amplifier'
  },
  {
    slug: 'sampo',
    score: 3148,
    usage: 0.32,
    role: 'specialist'
  },
  {
    slug: 'seele',
    score: 3199,
    usage: 0.87,
    role: 'dps'
  },
  {
    slug: 'serval',
    score: 3000,
    usage: 0.11,
    role: 'dps'
  },
  {
    slug: 'silver-wolf',
    score: 3265,
    usage: 12.29,
    role: 'amplifier'
  },
  {
    slug: 'sparkle',
    score: 3283,
    usage: 25.9,
    role: 'amplifier'
  },
  {
    slug: 'sushang',
    score: 3169,
    usage: 0.33,
    role: 'specialist'
  },
  {
    slug: 'tingyun',
    score: 3300,
    usage: 11.38,
    role: 'amplifier'
  },
  {
    slug: 'topaz',
    score: 3477,
    usage: 31.79,
    role: 'specialist'
  },
  {
    slug: 'trailblazer-fire',
    score: 3000,
    usage: 0.05,
    role: 'specialist'
  },
  {
    slug: 'trailblazer-imaginary',
    score: 3484,
    usage: 75.96,
    role: 'amplifier'
  },
  {
    slug: 'trailblazer-physical',
    score: 3000,
    usage: 0.0,
    role: 'dps'
  },
  {
    slug: 'welt',
    score: 3112,
    usage: 0.46,
    role: 'specialist'
  },
  {
    slug: 'xueyi',
    score: 3000,
    usage: 0.11,
    role: 'specialist'
  },
  {
    slug: 'yanqing',
    score: 3140,
    usage: 0.26,
    role: 'dps'
  },
  {
    slug: 'yukong',
    score: 3000,
    usage: 0.2,
    role: 'amplifier'
  },
  {
    slug: 'yunli',
    score: 3368,
    usage: 12.45,
    role: 'dps'
  }
];
