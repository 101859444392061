export const ApoCurrentAll = [
  {
    char_one: 'Firefly',
    char_two: 'trailblazer-imaginary',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 32.25,
    rank: 1,
    avg_round: 3392,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'trailblazer-imaginary',
    char_three: 'ruan-mei',
    char_four: 'Lingsha',
    app_rate: 22.08,
    rank: 2,
    avg_round: 3532,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 19.5,
    rank: 3,
    avg_round: 3647,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 7.12,
    rank: 4,
    avg_round: 3593,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'trailblazer-imaginary',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 6.55,
    rank: 5,
    avg_round: 3498,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'trailblazer-imaginary',
    char_three: 'ruan-mei',
    char_four: 'Lingsha',
    app_rate: 5.16,
    rank: 6,
    avg_round: 3626,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 4.67,
    rank: 7,
    avg_round: 3509,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 3.81,
    rank: 8,
    avg_round: 3524,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'trailblazer-imaginary',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 3.7,
    rank: 9,
    avg_round: 3197,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 3.22,
    rank: 10,
    avg_round: 3440,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 2.92,
    rank: 11,
    avg_round: 3513,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'Himeko',
    char_three: 'trailblazer-imaginary',
    char_four: 'ruan-mei',
    app_rate: 2.38,
    rank: 12,
    avg_round: 3516,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'Robin',
    char_four: 'Gallagher',
    app_rate: 2.26,
    rank: 13,
    avg_round: 3570,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 2.09,
    rank: 14,
    avg_round: 3350,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'Robin',
    char_four: 'Luocha',
    app_rate: 1.96,
    rank: 15,
    avg_round: 3513,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 1.73,
    rank: 16,
    avg_round: 3510,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Lingsha',
    app_rate: 1.65,
    rank: 17,
    avg_round: 3629,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Gallagher',
    app_rate: 1.32,
    rank: 18,
    avg_round: 3620,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'Firefly',
    char_three: 'trailblazer-imaginary',
    char_four: 'ruan-mei',
    app_rate: 1.26,
    rank: 19,
    avg_round: 3602,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Moze',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 1.25,
    rank: 20,
    avg_round: 3499,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'Robin',
    char_four: 'Lingsha',
    app_rate: 1.21,
    rank: 21,
    avg_round: 3579,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Luocha',
    app_rate: 1.2,
    rank: 22,
    avg_round: 3524,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Pela',
    char_four: 'fu-xuan',
    app_rate: 1.08,
    rank: 23,
    avg_round: 3369,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'silver-wolf',
    char_four: 'fu-xuan',
    app_rate: 1.06,
    rank: 24,
    avg_round: 3343,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 1.03,
    rank: 25,
    avg_round: 3293,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'silver-wolf',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 1.02,
    rank: 26,
    avg_round: 3144,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Robin',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 1.01,
    rank: 27,
    avg_round: 3309,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Pela',
    char_four: 'Gallagher',
    app_rate: 1.0,
    rank: 28,
    avg_round: 3420,
    star_num: '4'
  },
  {
    char_one: 'Boothill',
    char_two: 'trailblazer-imaginary',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.87,
    rank: 29,
    avg_round: 3447,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'trailblazer-imaginary',
    char_three: 'ruan-mei',
    char_four: 'Lingsha',
    app_rate: 0.85,
    rank: 30,
    avg_round: 3425,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'march-7th-swordmaster',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.79,
    rank: 31,
    avg_round: 3425,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'Bronya',
    char_four: 'Robin',
    app_rate: 0.71,
    rank: 32,
    avg_round: 3798,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Sparkle',
    char_four: 'Gallagher',
    app_rate: 0.69,
    rank: 33,
    avg_round: 3377,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 0.66,
    rank: 34,
    avg_round: 3016,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'Huohuo',
    app_rate: 0.63,
    rank: 35,
    avg_round: 3367,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'trailblazer-imaginary',
    char_three: 'ruan-mei',
    char_four: 'Luocha',
    app_rate: 0.62,
    rank: 36,
    avg_round: 3305,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'silver-wolf',
    char_four: 'fu-xuan',
    app_rate: 0.6,
    rank: 37,
    avg_round: 3214,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Pela',
    char_four: 'Aventurine',
    app_rate: 0.58,
    rank: 38,
    avg_round: 3378,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'trailblazer-imaginary',
    char_three: 'ruan-mei',
    char_four: 'Huohuo',
    app_rate: 0.58,
    rank: 38,
    avg_round: 3186,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 0.57,
    rank: 40,
    avg_round: 3282,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'march-7th-swordmaster',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.57,
    rank: 40,
    avg_round: 3378,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'black-swan',
    char_four: 'fu-xuan',
    app_rate: 0.56,
    rank: 42,
    avg_round: 3199,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.52,
    rank: 43,
    avg_round: 3396,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Yunli',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.49,
    rank: 44,
    avg_round: 3519,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.49,
    rank: 44,
    avg_round: 3431,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'trailblazer-imaginary',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.49,
    rank: 44,
    avg_round: 3233,
    star_num: '4'
  },
  {
    char_one: 'Boothill',
    char_two: 'Bronya',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.42,
    rank: 47,
    avg_round: 3340,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'Moze',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.42,
    rank: 47,
    avg_round: 3371,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Yunli',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.41,
    rank: 49,
    avg_round: 3526,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'Bronya',
    char_four: 'Aventurine',
    app_rate: 0.41,
    rank: 49,
    avg_round: 3482,
    star_num: '4'
  },
  {
    char_one: 'march-7th-swordmaster',
    char_two: 'trailblazer-imaginary',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.4,
    rank: 51,
    avg_round: 3297,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'silver-wolf',
    char_four: 'Gallagher',
    app_rate: 0.39,
    rank: 52,
    avg_round: 3395,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'Himeko',
    char_three: 'trailblazer-imaginary',
    char_four: 'ruan-mei',
    app_rate: 0.39,
    rank: 52,
    avg_round: 3653,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'Himeko',
    char_three: 'trailblazer-imaginary',
    char_four: 'Gallagher',
    app_rate: 0.39,
    rank: 52,
    avg_round: 3200,
    star_num: '4'
  },
  {
    char_one: 'march-7th-swordmaster',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.38,
    rank: 55,
    avg_round: 3386,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.36,
    rank: 56,
    avg_round: 3308,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'march-7th-swordmaster',
    char_three: 'Robin',
    char_four: 'Gallagher',
    app_rate: 0.36,
    rank: 56,
    avg_round: 3354,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'topaz',
    char_four: 'Aventurine',
    app_rate: 0.36,
    rank: 56,
    avg_round: 3448,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'trailblazer-imaginary',
    char_three: 'ruan-mei',
    char_four: 'Huohuo',
    app_rate: 0.35,
    rank: 59,
    avg_round: 3339,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Jiaoqiu',
    char_four: 'fu-xuan',
    app_rate: 0.34,
    rank: 60,
    avg_round: 3339,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.34,
    rank: 60,
    avg_round: 3392,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'topaz',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.34,
    rank: 60,
    avg_round: 3438,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Guinaifen',
    char_three: 'Jiaoqiu',
    char_four: 'ruan-mei',
    app_rate: 0.32,
    rank: 63,
    avg_round: 3745,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'Jiaoqiu',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.31,
    rank: 64,
    avg_round: 3440,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'silver-wolf',
    char_four: 'Gallagher',
    app_rate: 0.3,
    rank: 65,
    avg_round: 3248,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Himeko',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.3,
    rank: 65,
    avg_round: 3321,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'silver-wolf',
    char_four: 'fu-xuan',
    app_rate: 0.3,
    rank: 65,
    avg_round: 3255,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'black-swan',
    char_four: 'Gallagher',
    app_rate: 0.29,
    rank: 68,
    avg_round: 3230,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'Bronya',
    char_three: 'trailblazer-imaginary',
    char_four: 'ruan-mei',
    app_rate: 0.29,
    rank: 68,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Boothill',
    char_two: 'trailblazer-imaginary',
    char_three: 'ruan-mei',
    char_four: 'Lingsha',
    app_rate: 0.29,
    rank: 68,
    avg_round: 3431,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'Robin',
    char_three: 'silver-wolf',
    char_four: 'Aventurine',
    app_rate: 0.29,
    rank: 68,
    avg_round: 3374,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.28,
    rank: 72,
    avg_round: 3353,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'Luocha',
    app_rate: 0.28,
    rank: 72,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'Huohuo',
    app_rate: 0.27,
    rank: 74,
    avg_round: 3158,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'Aventurine',
    app_rate: 0.27,
    rank: 74,
    avg_round: 3087,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Yunli',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.26,
    rank: 77,
    avg_round: 3523,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Sparkle',
    char_four: 'Lingsha',
    app_rate: 0.26,
    rank: 77,
    avg_round: 3304,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'topaz',
    char_three: 'Bronya',
    char_four: 'Aventurine',
    app_rate: 0.26,
    rank: 77,
    avg_round: 3525,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'silver-wolf',
    char_four: 'Aventurine',
    app_rate: 0.26,
    rank: 77,
    avg_round: 3376,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.26,
    rank: 77,
    avg_round: 3417,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'trailblazer-imaginary',
    char_three: 'ruan-mei',
    char_four: 'Lingsha',
    app_rate: 0.26,
    rank: 77,
    avg_round: 3604,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'fu-xuan',
    app_rate: 0.25,
    rank: 83,
    avg_round: 3233,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'march-7th-swordmaster',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.25,
    rank: 83,
    avg_round: 3297,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Luocha',
    app_rate: 0.24,
    rank: 86,
    avg_round: 3310,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.24,
    rank: 86,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'march-7th-swordmaster',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.23,
    rank: 88,
    avg_round: 3415,
    star_num: '4'
  },
  {
    char_one: 'Qingque',
    char_two: 'silver-wolf',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.23,
    rank: 88,
    avg_round: 2999,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'Firefly',
    char_three: 'trailblazer-imaginary',
    char_four: 'Gallagher',
    app_rate: 0.22,
    rank: 91,
    avg_round: 3413,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.22,
    rank: 91,
    avg_round: 3226,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.22,
    rank: 91,
    avg_round: 3204,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.22,
    rank: 91,
    avg_round: 3410,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'trailblazer-imaginary',
    char_three: 'ruan-mei',
    char_four: 'Luocha',
    app_rate: 0.22,
    rank: 91,
    avg_round: 3402,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Robin',
    char_three: 'Tingyun',
    char_four: 'Aventurine',
    app_rate: 0.21,
    rank: 97,
    avg_round: 3445,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Robin',
    char_three: 'Tingyun',
    char_four: 'fu-xuan',
    app_rate: 0.21,
    rank: 97,
    avg_round: 3274,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Jiaoqiu',
    char_four: 'Aventurine',
    app_rate: 0.21,
    rank: 97,
    avg_round: 3368,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'Luocha',
    app_rate: 0.21,
    rank: 97,
    avg_round: 3186,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'black-swan',
    char_four: 'Aventurine',
    app_rate: 0.2,
    rank: 101,
    avg_round: 3199,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.2,
    rank: 101,
    avg_round: 3433,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.2,
    rank: 101,
    avg_round: 3424,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'Jiaoqiu',
    char_four: 'fu-xuan',
    app_rate: 0.2,
    rank: 101,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'march-7th-swordmaster',
    char_two: 'trailblazer-imaginary',
    char_three: 'ruan-mei',
    char_four: 'Lingsha',
    app_rate: 0.19,
    rank: 105,
    avg_round: 3421,
    star_num: '4'
  },
  {
    char_one: 'march-7th-swordmaster',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.19,
    rank: 105,
    avg_round: 3352,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Moze',
    char_three: 'Robin',
    char_four: 'Gallagher',
    app_rate: 0.19,
    rank: 105,
    avg_round: 3483,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Jiaoqiu',
    char_four: 'Gallagher',
    app_rate: 0.19,
    rank: 105,
    avg_round: 3329,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'ruan-mei',
    char_four: 'Sparkle',
    app_rate: 0.19,
    rank: 105,
    avg_round: 3610,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'Robin',
    char_four: 'Bailu',
    app_rate: 0.19,
    rank: 105,
    avg_round: 3447,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'march-7th-swordmaster',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.18,
    rank: 111,
    avg_round: 3327,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Robin',
    char_four: 'Gallagher',
    app_rate: 0.18,
    rank: 111,
    avg_round: 3470,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'ruan-mei',
    char_four: 'Huohuo',
    app_rate: 0.18,
    rank: 111,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'trailblazer-imaginary',
    char_three: 'ruan-mei',
    char_four: 'Aventurine',
    app_rate: 0.16,
    rank: 115,
    avg_round: 3250,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Himeko',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.16,
    rank: 115,
    avg_round: 3274,
    star_num: '4'
  },
  {
    char_one: 'march-7th-swordmaster',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.16,
    rank: 115,
    avg_round: 3287,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'trailblazer-imaginary',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.16,
    rank: 115,
    avg_round: 3318,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'Bronya',
    char_four: 'fu-xuan',
    app_rate: 0.15,
    rank: 120,
    avg_round: 3438,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'Firefly',
    char_three: 'trailblazer-imaginary',
    char_four: 'Lingsha',
    app_rate: 0.15,
    rank: 120,
    avg_round: 3540,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'black-swan',
    char_four: 'Huohuo',
    app_rate: 0.15,
    rank: 120,
    avg_round: 3219,
    star_num: '4'
  },
  {
    char_one: 'march-7th-swordmaster',
    char_two: 'Bronya',
    char_three: 'Robin',
    char_four: 'Gallagher',
    app_rate: 0.15,
    rank: 120,
    avg_round: 3393,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Gallagher',
    app_rate: 0.15,
    rank: 120,
    avg_round: 3432,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'Himeko',
    char_three: 'trailblazer-imaginary',
    char_four: 'Gallagher',
    app_rate: 0.15,
    rank: 120,
    avg_round: 3325,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'silver-wolf',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.15,
    rank: 120,
    avg_round: 3116,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Guinaifen',
    char_three: 'Jiaoqiu',
    char_four: 'Gallagher',
    app_rate: 0.15,
    rank: 120,
    avg_round: 3462,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Sparkle',
    char_four: 'Luocha',
    app_rate: 0.15,
    rank: 120,
    avg_round: 3350,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'march-7th-swordmaster',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.15,
    rank: 120,
    avg_round: 3107,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Moze',
    char_three: 'Robin',
    char_four: 'Luocha',
    app_rate: 0.14,
    rank: 130,
    avg_round: 3468,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'Jiaoqiu',
    char_four: 'Gallagher',
    app_rate: 0.14,
    rank: 130,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Himeko',
    char_three: 'trailblazer-imaginary',
    char_four: 'Gallagher',
    app_rate: 0.14,
    rank: 130,
    avg_round: 3137,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Guinaifen',
    char_four: 'Gallagher',
    app_rate: 0.14,
    rank: 130,
    avg_round: 3284,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'Bronya',
    char_four: 'Luocha',
    app_rate: 0.14,
    rank: 130,
    avg_round: 3422,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'Sparkle',
    char_four: 'Huohuo',
    app_rate: 0.14,
    rank: 130,
    avg_round: 3281,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Pela',
    char_four: 'Luocha',
    app_rate: 0.14,
    rank: 130,
    avg_round: 3360,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Guinaifen',
    char_three: 'Jiaoqiu',
    char_four: 'fu-xuan',
    app_rate: 0.14,
    rank: 130,
    avg_round: 3332,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'topaz',
    char_four: 'fu-xuan',
    app_rate: 0.14,
    rank: 130,
    avg_round: 3338,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Guinaifen',
    char_four: 'fu-xuan',
    app_rate: 0.13,
    rank: 140,
    avg_round: 3183,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.13,
    rank: 140,
    avg_round: 3325,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'march-7th-swordmaster',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.13,
    rank: 140,
    avg_round: 3210,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Bronya',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.13,
    rank: 140,
    avg_round: 3611,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'topaz',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.13,
    rank: 140,
    avg_round: 3395,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Feixiao',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.13,
    rank: 140,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Lingsha',
    app_rate: 0.13,
    rank: 140,
    avg_round: 3397,
    star_num: '4'
  },
  {
    char_one: 'Boothill',
    char_two: 'trailblazer-imaginary',
    char_three: 'ruan-mei',
    char_four: 'Aventurine',
    app_rate: 0.13,
    rank: 140,
    avg_round: 3411,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'silver-wolf',
    char_four: 'Aventurine',
    app_rate: 0.13,
    rank: 140,
    avg_round: 3250,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'ruan-mei',
    char_four: 'Lingsha',
    app_rate: 0.13,
    rank: 140,
    avg_round: 3303,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Bronya',
    char_three: 'Robin',
    char_four: 'Gallagher',
    app_rate: 0.13,
    rank: 140,
    avg_round: 3654,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Guinaifen',
    char_three: 'Pela',
    char_four: 'Gallagher',
    app_rate: 0.12,
    rank: 152,
    avg_round: 3314,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.12,
    rank: 152,
    avg_round: 3467,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'march-7th-swordmaster',
    char_three: 'trailblazer-imaginary',
    char_four: 'Lingsha',
    app_rate: 0.12,
    rank: 152,
    avg_round: 3376,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Robin',
    char_three: 'Tingyun',
    char_four: 'Luocha',
    app_rate: 0.12,
    rank: 152,
    avg_round: 3245,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Jiaoqiu',
    char_four: 'fu-xuan',
    app_rate: 0.12,
    rank: 152,
    avg_round: 3239,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'Himeko',
    char_three: 'trailblazer-imaginary',
    char_four: 'Lingsha',
    app_rate: 0.12,
    rank: 152,
    avg_round: 3390,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Yunli',
    char_three: 'Robin',
    char_four: 'Luocha',
    app_rate: 0.12,
    rank: 152,
    avg_round: 3519,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'Robin',
    char_three: 'silver-wolf',
    char_four: 'Gallagher',
    app_rate: 0.12,
    rank: 152,
    avg_round: 3328,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'Robin',
    char_three: 'silver-wolf',
    char_four: 'Huohuo',
    app_rate: 0.12,
    rank: 152,
    avg_round: 3258,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'Moze',
    char_four: 'Aventurine',
    app_rate: 0.11,
    rank: 163,
    avg_round: 3322,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Clara',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.11,
    rank: 163,
    avg_round: 3380,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'march-7th-swordmaster',
    char_three: 'Robin',
    char_four: 'Luocha',
    app_rate: 0.11,
    rank: 163,
    avg_round: 3329,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'Robin',
    char_four: 'Lynx',
    app_rate: 0.11,
    rank: 163,
    avg_round: 3479,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.11,
    rank: 163,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'topaz',
    char_four: 'Huohuo',
    app_rate: 0.11,
    rank: 163,
    avg_round: 3398,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Sparkle',
    char_four: 'Huohuo',
    app_rate: 0.11,
    rank: 163,
    avg_round: 3331,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'topaz',
    char_three: 'ruan-mei',
    char_four: 'Aventurine',
    app_rate: 0.11,
    rank: 163,
    avg_round: 3333,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Moze',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.11,
    rank: 163,
    avg_round: 3370,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'Jiaoqiu',
    char_four: 'Aventurine',
    app_rate: 0.11,
    rank: 163,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Himeko',
    char_three: 'Robin',
    char_four: 'Gallagher',
    app_rate: 0.1,
    rank: 175,
    avg_round: 3313,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'silver-wolf',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.1,
    rank: 175,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'trailblazer-imaginary',
    char_three: 'Jiaoqiu',
    char_four: 'Gallagher',
    app_rate: 0.1,
    rank: 175,
    avg_round: 3104,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Himeko',
    char_three: 'ruan-mei',
    char_four: 'Lingsha',
    app_rate: 0.1,
    rank: 175,
    avg_round: 3507,
    star_num: '4'
  },
  {
    char_one: 'Boothill',
    char_two: 'trailblazer-imaginary',
    char_three: 'ruan-mei',
    char_four: 'Huohuo',
    app_rate: 0.1,
    rank: 175,
    avg_round: 3342,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'silver-wolf',
    char_four: 'Huohuo',
    app_rate: 0.1,
    rank: 175,
    avg_round: 3292,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Pela',
    char_four: 'Lingsha',
    app_rate: 0.1,
    rank: 175,
    avg_round: 3468,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Himeko',
    char_three: 'ruan-mei',
    char_four: 'Luocha',
    app_rate: 0.1,
    rank: 175,
    avg_round: 3154,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'Robin',
    char_three: 'silver-wolf',
    char_four: 'fu-xuan',
    app_rate: 0.1,
    rank: 175,
    avg_round: 3188,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Jiaoqiu',
    char_four: 'Huohuo',
    app_rate: 0.1,
    rank: 175,
    avg_round: 3285,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Moze',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.1,
    rank: 175,
    avg_round: 3416,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'trailblazer-imaginary',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.1,
    rank: 175,
    avg_round: 3292,
    star_num: '4'
  },
  {
    char_one: 'Boothill',
    char_two: 'Bronya',
    char_three: 'ruan-mei',
    char_four: 'Luocha',
    app_rate: 0.1,
    rank: 175,
    avg_round: 3358,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'Bronya',
    char_four: 'Huohuo',
    app_rate: 0.1,
    rank: 175,
    avg_round: 3449,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'black-swan',
    char_four: 'Luocha',
    app_rate: 0.1,
    rank: 175,
    avg_round: 3184,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Luocha',
    app_rate: 0.1,
    rank: 175,
    avg_round: 3351,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Jiaoqiu',
    char_four: 'ruan-mei',
    app_rate: 0.09,
    rank: 192,
    avg_round: 3623,
    star_num: '4'
  },
  {
    char_one: 'Boothill',
    char_two: 'trailblazer-imaginary',
    char_three: 'ruan-mei',
    char_four: 'Luocha',
    app_rate: 0.09,
    rank: 192,
    avg_round: 3295,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Pela',
    char_four: 'Gepard',
    app_rate: 0.09,
    rank: 192,
    avg_round: 3303,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Yunli',
    char_three: 'topaz',
    char_four: 'Aventurine',
    app_rate: 0.09,
    rank: 192,
    avg_round: 3418,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'Bronya',
    char_three: 'trailblazer-imaginary',
    char_four: 'Gallagher',
    app_rate: 0.09,
    rank: 192,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'ruan-mei',
    char_four: 'Aventurine',
    app_rate: 0.09,
    rank: 192,
    avg_round: 3268,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'topaz',
    char_three: 'ruan-mei',
    char_four: 'Aventurine',
    app_rate: 0.09,
    rank: 192,
    avg_round: 3540,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'Jade',
    char_three: 'trailblazer-imaginary',
    char_four: 'ruan-mei',
    app_rate: 0.09,
    rank: 192,
    avg_round: 3762,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'Pela',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.09,
    rank: 192,
    avg_round: 3377,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'silver-wolf',
    char_four: 'Lingsha',
    app_rate: 0.09,
    rank: 192,
    avg_round: 3373,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'Bronya',
    char_four: 'Gallagher',
    app_rate: 0.09,
    rank: 192,
    avg_round: 3425,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Jiaoqiu',
    char_four: 'Aventurine',
    app_rate: 0.09,
    rank: 192,
    avg_round: 3416,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'trailblazer-imaginary',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.09,
    rank: 192,
    avg_round: 3187,
    star_num: '4'
  },
  {
    char_one: 'Boothill',
    char_two: 'Bronya',
    char_three: 'ruan-mei',
    char_four: 'Aventurine',
    app_rate: 0.09,
    rank: 192,
    avg_round: 3248,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Guinaifen',
    char_three: 'silver-wolf',
    char_four: 'fu-xuan',
    app_rate: 0.09,
    rank: 192,
    avg_round: 3103,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'march-7th-swordmaster',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 0.08,
    rank: 207,
    avg_round: 3246,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Clara',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.08,
    rank: 207,
    avg_round: 3275,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Bronya',
    char_three: 'Robin',
    char_four: 'Luocha',
    app_rate: 0.08,
    rank: 207,
    avg_round: 3541,
    star_num: '4'
  },
  {
    char_one: 'trailblazer-imaginary',
    char_two: 'Jiaoqiu',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.08,
    rank: 207,
    avg_round: 3214,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'topaz',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.08,
    rank: 207,
    avg_round: 3292,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.08,
    rank: 207,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'ruan-mei',
    char_four: 'Luocha',
    app_rate: 0.08,
    rank: 207,
    avg_round: 3263,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'Asta',
    char_three: 'trailblazer-imaginary',
    char_four: 'Lingsha',
    app_rate: 0.08,
    rank: 207,
    avg_round: 3298,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'march-7th-swordmaster',
    char_three: 'Sparkle',
    char_four: 'Huohuo',
    app_rate: 0.08,
    rank: 207,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.08,
    rank: 207,
    avg_round: 3491,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Jade',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.08,
    rank: 207,
    avg_round: 3238,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Guinaifen',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.08,
    rank: 207,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Jade',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.08,
    rank: 207,
    avg_round: 3328,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'dr-ratio',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.08,
    rank: 207,
    avg_round: 3496,
    star_num: '4'
  },
  {
    char_one: 'trailblazer-imaginary',
    char_two: 'ruan-mei',
    char_three: 'Gallagher',
    char_four: 'Lingsha',
    app_rate: 0.08,
    rank: 207,
    avg_round: 3353,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'march-7th-swordmaster',
    char_three: 'Robin',
    char_four: 'Lingsha',
    app_rate: 0.08,
    rank: 207,
    avg_round: 3342,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'trailblazer-imaginary',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.08,
    rank: 207,
    avg_round: 3306,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'Acheron',
    char_three: 'trailblazer-imaginary',
    char_four: 'ruan-mei',
    app_rate: 0.08,
    rank: 207,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Bailu',
    app_rate: 0.08,
    rank: 207,
    avg_round: 3440,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Clara',
    char_three: 'Robin',
    char_four: 'Luocha',
    app_rate: 0.08,
    rank: 207,
    avg_round: 3324,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'black-swan',
    char_four: 'Lingsha',
    app_rate: 0.08,
    rank: 207,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'Asta',
    char_three: 'trailblazer-imaginary',
    char_four: 'Gallagher',
    app_rate: 0.08,
    rank: 207,
    avg_round: 3337,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'silver-wolf',
    char_three: 'Sparkle',
    char_four: 'Gallagher',
    app_rate: 0.07,
    rank: 231,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'trailblazer-imaginary',
    char_three: 'ruan-mei',
    char_four: 'Bailu',
    app_rate: 0.07,
    rank: 231,
    avg_round: 3182,
    star_num: '4'
  },
  {
    char_one: 'Guinaifen',
    char_two: 'trailblazer-imaginary',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.07,
    rank: 231,
    avg_round: 3220,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'silver-wolf',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.07,
    rank: 231,
    avg_round: 3221,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'topaz',
    char_three: 'Jiaoqiu',
    char_four: 'Aventurine',
    app_rate: 0.07,
    rank: 231,
    avg_round: 3483,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'Jiaoqiu',
    char_four: 'Luocha',
    app_rate: 0.07,
    rank: 231,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'march-7th-swordmaster',
    char_three: 'trailblazer-imaginary',
    char_four: 'Gallagher',
    app_rate: 0.07,
    rank: 231,
    avg_round: 3115,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Pela',
    char_four: 'Huohuo',
    app_rate: 0.07,
    rank: 231,
    avg_round: 3312,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Sparkle',
    char_four: 'Gallagher',
    app_rate: 0.07,
    rank: 231,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'Robin',
    char_four: 'Gepard',
    app_rate: 0.07,
    rank: 231,
    avg_round: 3488,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'silver-wolf',
    char_four: 'Aventurine',
    app_rate: 0.07,
    rank: 231,
    avg_round: 3292,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Yunli',
    char_three: 'topaz',
    char_four: 'Robin',
    app_rate: 0.07,
    rank: 231,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Yunli',
    char_three: 'topaz',
    char_four: 'Huohuo',
    app_rate: 0.06,
    rank: 245,
    avg_round: 3395,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.06,
    rank: 245,
    avg_round: 3152,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Guinaifen',
    char_three: 'silver-wolf',
    char_four: 'Gallagher',
    app_rate: 0.06,
    rank: 245,
    avg_round: 3206,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'silver-wolf',
    char_four: 'fu-xuan',
    app_rate: 0.06,
    rank: 245,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'march-7th-swordmaster',
    char_three: 'Robin',
    char_four: 'Gallagher',
    app_rate: 0.06,
    rank: 245,
    avg_round: 3222,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'trailblazer-imaginary',
    char_three: 'ruan-mei',
    char_four: 'Lynx',
    app_rate: 0.06,
    rank: 245,
    avg_round: 3183,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'trailblazer-imaginary',
    char_three: 'Jiaoqiu',
    char_four: 'ruan-mei',
    app_rate: 0.06,
    rank: 245,
    avg_round: 3443,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'Bronya',
    char_three: 'trailblazer-imaginary',
    char_four: 'Lingsha',
    app_rate: 0.06,
    rank: 245,
    avg_round: 3212,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'ruan-mei',
    char_three: 'silver-wolf',
    char_four: 'fu-xuan',
    app_rate: 0.06,
    rank: 245,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.06,
    rank: 245,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Bronya',
    char_three: 'ruan-mei',
    char_four: 'Lingsha',
    app_rate: 0.06,
    rank: 245,
    avg_round: 3612,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'trailblazer-imaginary',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.06,
    rank: 245,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'silver-wolf',
    char_four: 'Gallagher',
    app_rate: 0.06,
    rank: 245,
    avg_round: 3190,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Himeko',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.06,
    rank: 245,
    avg_round: 3071,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'Pela',
    char_four: 'fu-xuan',
    app_rate: 0.06,
    rank: 245,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'Bronya',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.06,
    rank: 245,
    avg_round: 3148,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Robin',
    char_three: 'Tingyun',
    char_four: 'Gallagher',
    app_rate: 0.06,
    rank: 245,
    avg_round: 3395,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'ruan-mei',
    char_four: 'Aventurine',
    app_rate: 0.06,
    rank: 245,
    avg_round: 3548,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'Pela',
    char_four: 'Gallagher',
    app_rate: 0.06,
    rank: 245,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'ruan-mei',
    char_three: 'Tingyun',
    char_four: 'Gallagher',
    app_rate: 0.06,
    rank: 245,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'Aventurine',
    app_rate: 0.06,
    rank: 245,
    avg_round: 3357,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'Sparkle',
    char_four: 'Luocha',
    app_rate: 0.06,
    rank: 245,
    avg_round: 3321,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'trailblazer-imaginary',
    char_three: 'Pela',
    char_four: 'Gallagher',
    app_rate: 0.06,
    rank: 245,
    avg_round: 3182,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'ruan-mei',
    char_four: 'silver-wolf',
    app_rate: 0.06,
    rank: 245,
    avg_round: 3499,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'Asta',
    char_three: 'trailblazer-imaginary',
    char_four: 'Gallagher',
    app_rate: 0.05,
    rank: 272,
    avg_round: 3178,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Himeko',
    char_three: 'Jiaoqiu',
    char_four: 'Sparkle',
    app_rate: 0.05,
    rank: 272,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Asta',
    char_three: 'trailblazer-imaginary',
    char_four: 'Gallagher',
    app_rate: 0.05,
    rank: 272,
    avg_round: 2978,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'ruan-mei',
    char_three: 'Tingyun',
    char_four: 'Gallagher',
    app_rate: 0.05,
    rank: 272,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Himeko',
    char_three: 'Sparkle',
    char_four: 'Lingsha',
    app_rate: 0.05,
    rank: 272,
    avg_round: 3254,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'Jiaoqiu',
    char_four: 'Aventurine',
    app_rate: 0.05,
    rank: 272,
    avg_round: 3484,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'Yunli',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.05,
    rank: 272,
    avg_round: 3470,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Himeko',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.05,
    rank: 272,
    avg_round: 3492,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Yunli',
    char_three: 'Robin',
    char_four: 'Lingsha',
    app_rate: 0.05,
    rank: 272,
    avg_round: 3489,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Sparkle',
    char_four: 'Gepard',
    app_rate: 0.05,
    rank: 272,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Guinaifen',
    char_three: 'Sparkle',
    char_four: 'Gallagher',
    app_rate: 0.05,
    rank: 272,
    avg_round: 3334,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.05,
    rank: 272,
    avg_round: 3180,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'topaz',
    char_three: 'Bronya',
    char_four: 'Luocha',
    app_rate: 0.05,
    rank: 272,
    avg_round: 3434,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'Jiaoqiu',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.05,
    rank: 272,
    avg_round: 3337,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'topaz',
    char_four: 'Lingsha',
    app_rate: 0.05,
    rank: 272,
    avg_round: 3317,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.05,
    rank: 272,
    avg_round: 3556,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'topaz',
    char_three: 'Bronya',
    char_four: 'fu-xuan',
    app_rate: 0.05,
    rank: 272,
    avg_round: 3467,
    star_num: '4'
  },
  {
    char_one: 'Blade',
    char_two: 'march-7th-swordmaster',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.05,
    rank: 272,
    avg_round: 3214,
    star_num: '4'
  },
  {
    char_one: 'Blade',
    char_two: 'Bronya',
    char_three: 'Robin',
    char_four: 'Luocha',
    app_rate: 0.05,
    rank: 272,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Guinaifen',
    char_three: 'Pela',
    char_four: 'fu-xuan',
    app_rate: 0.05,
    rank: 272,
    avg_round: 3208,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Himeko',
    char_three: 'Jiaoqiu',
    char_four: 'Robin',
    app_rate: 0.05,
    rank: 272,
    avg_round: 3632,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'trailblazer-imaginary',
    char_three: 'Jiaoqiu',
    char_four: 'Gallagher',
    app_rate: 0.05,
    rank: 272,
    avg_round: 3360,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'Robin',
    char_three: 'Tingyun',
    char_four: 'Gallagher',
    app_rate: 0.05,
    rank: 272,
    avg_round: 3337,
    star_num: '4'
  },
  {
    char_one: 'Serval',
    char_two: 'trailblazer-imaginary',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.05,
    rank: 272,
    avg_round: 3184,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Jiaoqiu',
    char_four: 'Lingsha',
    app_rate: 0.05,
    rank: 272,
    avg_round: 3448,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Gepard',
    app_rate: 0.05,
    rank: 272,
    avg_round: 3508,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Moze',
    char_three: 'topaz',
    char_four: 'Aventurine',
    app_rate: 0.05,
    rank: 272,
    avg_round: 3390,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'silver-wolf',
    char_three: 'Sparkle',
    char_four: 'Gepard',
    app_rate: 0.05,
    rank: 272,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.05,
    rank: 272,
    avg_round: 3201,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'Jade',
    char_three: 'trailblazer-imaginary',
    char_four: 'Gallagher',
    app_rate: 0.05,
    rank: 272,
    avg_round: 3551,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Clara',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.05,
    rank: 272,
    avg_round: 3364,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'silver-wolf',
    char_three: 'Sparkle',
    char_four: 'Lingsha',
    app_rate: 0.05,
    rank: 272,
    avg_round: 3198,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'Himeko',
    char_three: 'trailblazer-imaginary',
    char_four: 'fu-xuan',
    app_rate: 0.05,
    rank: 272,
    avg_round: 3098,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Jiaoqiu',
    char_four: 'Luocha',
    app_rate: 0.05,
    rank: 272,
    avg_round: 3265,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'trailblazer-imaginary',
    char_three: 'Pela',
    char_four: 'Gallagher',
    app_rate: 0.05,
    rank: 272,
    avg_round: 3268,
    star_num: '4'
  },
  {
    char_one: 'Blade',
    char_two: 'march-7th-swordmaster',
    char_three: 'Robin',
    char_four: 'Gallagher',
    app_rate: 0.04,
    rank: 310,
    avg_round: 3141,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Feixiao',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.04,
    rank: 310,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Himeko',
    char_three: 'trailblazer-imaginary',
    char_four: 'Lingsha',
    app_rate: 0.04,
    rank: 310,
    avg_round: 3337,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'Lynx',
    app_rate: 0.04,
    rank: 310,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'Pela',
    char_three: 'Robin',
    char_four: 'Gallagher',
    app_rate: 0.04,
    rank: 310,
    avg_round: 3279,
    star_num: '4'
  },
  {
    char_one: 'Guinaifen',
    char_two: 'trailblazer-imaginary',
    char_three: 'ruan-mei',
    char_four: 'Lingsha',
    app_rate: 0.04,
    rank: 310,
    avg_round: 3352,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'Asta',
    char_three: 'trailblazer-imaginary',
    char_four: 'ruan-mei',
    app_rate: 0.04,
    rank: 310,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Guinaifen',
    char_four: 'Aventurine',
    app_rate: 0.04,
    rank: 310,
    avg_round: 3183,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'trailblazer-imaginary',
    char_three: 'Gallagher',
    char_four: 'Lingsha',
    app_rate: 0.04,
    rank: 310,
    avg_round: 3423,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'ruan-mei',
    char_four: 'Bailu',
    app_rate: 0.04,
    rank: 310,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Guinaifen',
    char_three: 'Pela',
    char_four: 'Aventurine',
    app_rate: 0.04,
    rank: 310,
    avg_round: 3297,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'ruan-mei',
    char_four: 'Gepard',
    app_rate: 0.04,
    rank: 310,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'Huohuo',
    app_rate: 0.04,
    rank: 310,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Himeko',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.04,
    rank: 310,
    avg_round: 3393,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'Moze',
    char_four: 'fu-xuan',
    app_rate: 0.04,
    rank: 310,
    avg_round: 3240,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'Luocha',
    app_rate: 0.04,
    rank: 310,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.04,
    rank: 310,
    avg_round: 3454,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'ruan-mei',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.04,
    rank: 310,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Yunli',
    char_three: 'march-7th-swordmaster',
    char_four: 'fu-xuan',
    app_rate: 0.04,
    rank: 310,
    avg_round: 3210,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'ruan-mei',
    char_four: 'Huohuo',
    app_rate: 0.04,
    rank: 310,
    avg_round: 3046,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'march-7th-swordmaster',
    char_three: 'trailblazer-imaginary',
    char_four: 'Lingsha',
    app_rate: 0.04,
    rank: 310,
    avg_round: 3192,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Yunli',
    char_three: 'Robin',
    char_four: 'Gallagher',
    app_rate: 0.04,
    rank: 310,
    avg_round: 3549,
    star_num: '4'
  },
  {
    char_one: 'Sushang',
    char_two: 'trailblazer-imaginary',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.04,
    rank: 310,
    avg_round: 3252,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Moze',
    char_three: 'topaz',
    char_four: 'fu-xuan',
    app_rate: 0.04,
    rank: 310,
    avg_round: 3309,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'Luocha',
    app_rate: 0.04,
    rank: 310,
    avg_round: 3302,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'topaz',
    char_three: 'Sparkle',
    char_four: 'Luocha',
    app_rate: 0.04,
    rank: 310,
    avg_round: 3417,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Jiaoqiu',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.04,
    rank: 310,
    avg_round: 3514,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'trailblazer-imaginary',
    char_three: 'silver-wolf',
    char_four: 'Gallagher',
    app_rate: 0.04,
    rank: 310,
    avg_round: 3234,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'Jiaoqiu',
    char_three: 'Robin',
    char_four: 'Gallagher',
    app_rate: 0.04,
    rank: 310,
    avg_round: 3329,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Yunli',
    char_three: 'Sparkle',
    char_four: 'Huohuo',
    app_rate: 0.04,
    rank: 310,
    avg_round: 3246,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Robin',
    char_three: 'ruan-mei',
    char_four: 'Huohuo',
    app_rate: 0.04,
    rank: 310,
    avg_round: 3415,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Pela',
    char_four: 'Gallagher',
    app_rate: 0.04,
    rank: 310,
    avg_round: 3201,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Bronya',
    char_four: 'fu-xuan',
    app_rate: 0.04,
    rank: 310,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'march-7th-swordmaster',
    char_three: 'Robin',
    char_four: 'Luocha',
    app_rate: 0.04,
    rank: 310,
    avg_round: 3256,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Gallagher',
    app_rate: 0.04,
    rank: 310,
    avg_round: 3347,
    star_num: '4'
  },
  {
    char_one: 'Serval',
    char_two: 'trailblazer-imaginary',
    char_three: 'ruan-mei',
    char_four: 'Lingsha',
    app_rate: 0.04,
    rank: 310,
    avg_round: 3432,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'topaz',
    char_three: 'Bronya',
    char_four: 'Lingsha',
    app_rate: 0.04,
    rank: 310,
    avg_round: 3507,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Lingsha',
    app_rate: 0.04,
    rank: 310,
    avg_round: 3550,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'march-7th-swordmaster',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.04,
    rank: 310,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Yunli',
    char_three: 'march-7th-swordmaster',
    char_four: 'Aventurine',
    app_rate: 0.04,
    rank: 310,
    avg_round: 3351,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'march-7th-swordmaster',
    char_three: 'Robin',
    char_four: 'Lynx',
    app_rate: 0.04,
    rank: 310,
    avg_round: 3115,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.04,
    rank: 310,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Guinaifen',
    char_three: 'Jiaoqiu',
    char_four: 'Aventurine',
    app_rate: 0.04,
    rank: 310,
    avg_round: 3402,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Jiaoqiu',
    char_four: 'Luocha',
    app_rate: 0.04,
    rank: 310,
    avg_round: 3283,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Jade',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.04,
    rank: 310,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'silver-wolf',
    char_three: 'Sparkle',
    char_four: 'Huohuo',
    app_rate: 0.04,
    rank: 310,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'march-7th-swordmaster',
    char_three: 'trailblazer-imaginary',
    char_four: 'ruan-mei',
    app_rate: 0.04,
    rank: 310,
    avg_round: 3442,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'trailblazer-imaginary',
    char_three: 'ruan-mei',
    char_four: 'Aventurine',
    app_rate: 0.04,
    rank: 310,
    avg_round: 3420,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.04,
    rank: 310,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Boothill',
    char_two: 'Bronya',
    char_three: 'trailblazer-imaginary',
    char_four: 'Gallagher',
    app_rate: 0.04,
    rank: 310,
    avg_round: 3274,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'silver-wolf',
    char_three: 'Tingyun',
    char_four: 'fu-xuan',
    app_rate: 0.04,
    rank: 310,
    avg_round: 3032,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'Pela',
    char_four: 'Gepard',
    app_rate: 0.04,
    rank: 310,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Lynx',
    app_rate: 0.04,
    rank: 310,
    avg_round: 3604,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'trailblazer-imaginary',
    char_three: 'ruan-mei',
    char_four: 'Luocha',
    app_rate: 0.04,
    rank: 310,
    avg_round: 2934,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'Jiaoqiu',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.04,
    rank: 310,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Clara',
    char_three: 'Robin',
    char_four: 'Lingsha',
    app_rate: 0.04,
    rank: 310,
    avg_round: 3465,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'ruan-mei',
    char_three: 'Sparkle',
    char_four: 'Lingsha',
    app_rate: 0.04,
    rank: 310,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Clara',
    char_three: 'Robin',
    char_four: 'Luocha',
    app_rate: 0.04,
    rank: 310,
    avg_round: 3304,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'silver-wolf',
    char_four: 'Luocha',
    app_rate: 0.03,
    rank: 379,
    avg_round: 3137,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'topaz',
    char_four: 'Luocha',
    app_rate: 0.03,
    rank: 379,
    avg_round: 3307,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'trailblazer-imaginary',
    char_three: 'ruan-mei',
    char_four: 'Gepard',
    app_rate: 0.03,
    rank: 379,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'Guinaifen',
    char_three: 'trailblazer-imaginary',
    char_four: 'ruan-mei',
    app_rate: 0.03,
    rank: 379,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'Pela',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 379,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Himeko',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 379,
    avg_round: 3206,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Welt',
    char_three: 'Jiaoqiu',
    char_four: 'ruan-mei',
    app_rate: 0.03,
    rank: 379,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Blade',
    char_two: 'march-7th-swordmaster',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 379,
    avg_round: 3226,
    star_num: '4'
  },
  {
    char_one: 'Boothill',
    char_two: 'march-7th-swordmaster',
    char_three: 'Bronya',
    char_four: 'Luocha',
    app_rate: 0.03,
    rank: 379,
    avg_round: 3248,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'Firefly',
    char_three: 'ruan-mei',
    char_four: 'Lingsha',
    app_rate: 0.03,
    rank: 379,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Sushang',
    char_two: 'march-7th-swordmaster',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 379,
    avg_round: 3190,
    star_num: '4'
  },
  {
    char_one: 'Xueyi',
    char_two: 'trailblazer-imaginary',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.03,
    rank: 379,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'ruan-mei',
    char_four: 'Lingsha',
    app_rate: 0.03,
    rank: 379,
    avg_round: 3515,
    star_num: '4'
  },
  {
    char_one: 'march-7th-swordmaster',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'Lingsha',
    app_rate: 0.03,
    rank: 379,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Pela',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 379,
    avg_round: 3316,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Clara',
    char_three: 'Robin',
    char_four: 'Gallagher',
    app_rate: 0.03,
    rank: 379,
    avg_round: 3488,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'Robin',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 0.03,
    rank: 379,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.03,
    rank: 379,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'Jiaoqiu',
    char_four: 'Robin',
    app_rate: 0.03,
    rank: 379,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Guinaifen',
    char_three: 'Jiaoqiu',
    char_four: 'Luocha',
    app_rate: 0.03,
    rank: 379,
    avg_round: 3320,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Robin',
    char_four: 'Gallagher',
    app_rate: 0.03,
    rank: 379,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'black-swan',
    char_four: 'ruan-mei',
    app_rate: 0.03,
    rank: 379,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 379,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 379,
    avg_round: 3054,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'silver-wolf',
    char_four: 'Lynx',
    app_rate: 0.03,
    rank: 379,
    avg_round: 3273,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Welt',
    char_three: 'Jiaoqiu',
    char_four: 'Luocha',
    app_rate: 0.03,
    rank: 379,
    avg_round: 3324,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'Pela',
    char_three: 'Tingyun',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 379,
    avg_round: 3336,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'ruan-mei',
    char_four: 'Gepard',
    app_rate: 0.03,
    rank: 379,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Moze',
    char_three: 'Robin',
    char_four: 'Lingsha',
    app_rate: 0.03,
    rank: 379,
    avg_round: 3502,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'Jiaoqiu',
    char_three: 'Sparkle',
    char_four: 'Luocha',
    app_rate: 0.03,
    rank: 379,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'Robin',
    char_three: 'Tingyun',
    char_four: 'Gallagher',
    app_rate: 0.03,
    rank: 379,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Jiaoqiu',
    char_four: 'Robin',
    app_rate: 0.03,
    rank: 379,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'march-7th-swordmaster',
    char_three: 'trailblazer-imaginary',
    char_four: 'ruan-mei',
    app_rate: 0.03,
    rank: 379,
    avg_round: 3260,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'Jiaoqiu',
    char_three: 'Sparkle',
    char_four: 'Huohuo',
    app_rate: 0.03,
    rank: 379,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'Clara',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 379,
    avg_round: 3114,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'march-7th-swordmaster',
    char_three: 'trailblazer-imaginary',
    char_four: 'Lingsha',
    app_rate: 0.03,
    rank: 379,
    avg_round: 3478,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Blade',
    char_three: 'Bronya',
    char_four: 'Luocha',
    app_rate: 0.03,
    rank: 379,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'Himeko',
    char_three: 'trailblazer-imaginary',
    char_four: 'Lingsha',
    app_rate: 0.03,
    rank: 379,
    avg_round: 3475,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'march-7th-swordmaster',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 379,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'silver-wolf',
    char_four: 'Luocha',
    app_rate: 0.03,
    rank: 379,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'black-swan',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 379,
    avg_round: 3409,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'Luocha',
    app_rate: 0.03,
    rank: 379,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Welt',
    char_three: 'Jiaoqiu',
    char_four: 'Gallagher',
    app_rate: 0.03,
    rank: 379,
    avg_round: 3312,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'topaz',
    char_four: 'Gallagher',
    app_rate: 0.03,
    rank: 379,
    avg_round: 3324,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'silver-wolf',
    char_four: 'Gepard',
    app_rate: 0.03,
    rank: 379,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Robin',
    char_four: 'Luocha',
    app_rate: 0.03,
    rank: 379,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'march-7th-swordmaster',
    char_three: 'silver-wolf',
    char_four: 'Huohuo',
    app_rate: 0.03,
    rank: 379,
    avg_round: 3105,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'silver-wolf',
    char_three: 'Tingyun',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 379,
    avg_round: 3364,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'silver-wolf',
    char_four: 'Luocha',
    app_rate: 0.03,
    rank: 379,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'Moze',
    char_three: 'Jiaoqiu',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 379,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'Sparkle',
    char_four: 'Luocha',
    app_rate: 0.03,
    rank: 379,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 379,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.03,
    rank: 379,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'march-7th-swordmaster',
    char_three: 'trailblazer-imaginary',
    char_four: 'Gallagher',
    app_rate: 0.03,
    rank: 379,
    avg_round: 3029,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'Sparkle',
    char_four: 'Lingsha',
    app_rate: 0.03,
    rank: 379,
    avg_round: 3488,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'ruan-mei',
    char_four: 'Lingsha',
    app_rate: 0.03,
    rank: 379,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'march-7th-swordmaster',
    char_three: 'Robin',
    char_four: 'Luocha',
    app_rate: 0.03,
    rank: 379,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Jiaoqiu',
    char_four: 'Huohuo',
    app_rate: 0.03,
    rank: 379,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.03,
    rank: 379,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Welt',
    char_three: 'Jiaoqiu',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 379,
    avg_round: 3300,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'silver-wolf',
    char_four: 'Gepard',
    app_rate: 0.03,
    rank: 379,
    avg_round: 3208,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Tingyun',
    app_rate: 0.03,
    rank: 379,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Sparkle',
    app_rate: 0.03,
    rank: 379,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'Robin',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'Robin',
    char_three: 'Tingyun',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Firefly',
    char_three: 'ruan-mei',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Seele',
    char_two: 'Jade',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 469,
    avg_round: 3208,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'Hanya',
    char_three: 'Sparkle',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Asta',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Guinaifen',
    char_three: 'Robin',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'march-7th-swordmaster',
    char_three: 'ruan-mei',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'silver-wolf',
    char_three: 'Sparkle',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 469,
    avg_round: 3062,
    star_num: '4'
  },
  {
    char_one: 'Boothill',
    char_two: 'Bronya',
    char_three: 'Pela',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'trailblazer-imaginary',
    char_two: 'ruan-mei',
    char_three: 'Tingyun',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 469,
    avg_round: 3341,
    star_num: '4'
  },
  {
    char_one: 'Welt',
    char_two: 'trailblazer-imaginary',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 469,
    avg_round: 3182,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Jiaoqiu',
    char_four: 'Gepard',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Feixiao',
    char_three: 'topaz',
    char_four: 'Robin',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'Jiaoqiu',
    char_four: 'Sparkle',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Pela',
    char_four: 'Gepard',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Himeko',
    char_three: 'Guinaifen',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'march-7th-swordmaster',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Welt',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'topaz',
    char_three: 'Hanya',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 469,
    avg_round: 3403,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'march-7th-swordmaster',
    char_three: 'ruan-mei',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'Asta',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 469,
    avg_round: 3387,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'topaz',
    char_three: 'Bronya',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Himeko',
    char_three: 'Kafka',
    char_four: 'Jiaoqiu',
    app_rate: 0.02,
    rank: 469,
    avg_round: 3541,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Jiaoqiu',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'trailblazer-imaginary',
    char_three: 'ruan-mei',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Guinaifen',
    char_three: 'Bronya',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Himeko',
    char_three: 'Kafka',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'march-7th-swordmaster',
    char_three: 'Tingyun',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 469,
    avg_round: 3307,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Clara',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 469,
    avg_round: 3309,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'topaz',
    char_three: 'Sparkle',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 469,
    avg_round: 3322,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Yunli',
    char_three: 'Robin',
    char_four: 'Sparkle',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'ruan-mei',
    char_three: 'Sparkle',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Sparkle',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'ruan-mei',
    char_three: 'Sparkle',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 469,
    avg_round: 3115,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'Yukong',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 469,
    avg_round: 3372,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'ruan-mei',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Blade',
    char_two: 'silver-wolf',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'Pela',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 469,
    avg_round: 3158,
    star_num: '4'
  },
  {
    char_one: 'Boothill',
    char_two: 'Bronya',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Yunli',
    char_three: 'topaz',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 469,
    avg_round: 3288,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'Sparkle',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Acheron',
    char_three: 'Guinaifen',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'topaz',
    char_three: 'Tingyun',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 469,
    avg_round: 3440,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'ruan-mei',
    char_four: 'Tingyun',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'march-7th-swordmaster',
    char_two: 'Robin',
    char_three: 'silver-wolf',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Welt',
    char_three: 'Pela',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 469,
    avg_round: 3244,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'ruan-mei',
    char_three: 'Tingyun',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 469,
    avg_round: 3240,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'Jiaoqiu',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'march-7th-swordmaster',
    char_three: 'trailblazer-imaginary',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 469,
    avg_round: 3387,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'silver-wolf',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Asta',
    char_two: 'trailblazer-imaginary',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'Boothill',
    char_three: 'trailblazer-imaginary',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'black-swan',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'Asta',
    char_three: 'trailblazer-imaginary',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'Firefly',
    char_three: 'Himeko',
    char_four: 'trailblazer-imaginary',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'trailblazer-imaginary',
    char_three: 'Pela',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 469,
    avg_round: 3286,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'Sparkle',
    char_four: 'Gepard',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Blade',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'Acheron',
    char_three: 'Jiaoqiu',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Bronya',
    char_three: 'Robin',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'dr-ratio',
    char_three: 'topaz',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'march-7th-swordmaster',
    char_three: 'silver-wolf',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Clara',
    char_three: 'march-7th-swordmaster',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 469,
    avg_round: 3212,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'ruan-mei',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Blade',
    char_two: 'Bronya',
    char_three: 'ruan-mei',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Jade',
    char_three: 'Bronya',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 469,
    avg_round: 3263,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'ruan-mei',
    char_three: 'Sparkle',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'Robin',
    char_three: 'Tingyun',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Seele',
    char_two: 'Robin',
    char_three: 'silver-wolf',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'Acheron',
    char_three: 'trailblazer-imaginary',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Robin',
    char_three: 'ruan-mei',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Guinaifen',
    char_three: 'Jiaoqiu',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'ruan-mei',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Pela',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'Jiaoqiu',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'Jiaoqiu',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'topaz',
    char_three: 'Sparkle',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Himeko',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'Jiaoqiu',
    char_four: 'Gepard',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Himeko',
    char_three: 'ruan-mei',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 469,
    avg_round: 3132,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Xueyi',
    char_three: 'ruan-mei',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Robin',
    char_three: 'Tingyun',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Boothill',
    char_two: 'Bronya',
    char_three: 'Robin',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Moze',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Boothill',
    char_two: 'march-7th-swordmaster',
    char_three: 'trailblazer-imaginary',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 469,
    avg_round: 3294,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Firefly',
    char_three: 'Himeko',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 469,
    avg_round: 3497,
    star_num: '4'
  },
  {
    char_one: 'Sushang',
    char_two: 'march-7th-swordmaster',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 469,
    avg_round: 3222,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'ruan-mei',
    char_four: 'Bailu',
    app_rate: 0.02,
    rank: 469,
    avg_round: 3450,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'topaz',
    char_three: 'Asta',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 469,
    avg_round: 3589,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Robin',
    char_three: 'Tingyun',
    char_four: 'Lynx',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'Pela',
    char_three: 'Tingyun',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 469,
    avg_round: 3150,
    star_num: '4'
  },
  {
    char_one: 'Boothill',
    char_two: 'Bronya',
    char_three: 'ruan-mei',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 469,
    avg_round: 3407,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'Bronya',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 469,
    avg_round: 3412,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Himeko',
    char_three: 'Jiaoqiu',
    char_four: 'silver-wolf',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'ruan-mei',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'Feixiao',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Yunli',
    char_three: 'march-7th-swordmaster',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 469,
    avg_round: 3238,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'Jiaoqiu',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Feixiao',
    char_three: 'Robin',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'silver-wolf',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'march-7th-swordmaster',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 469,
    avg_round: 3133,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'black-swan',
    char_three: 'Robin',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Qingque',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Himeko',
    char_three: 'Jiaoqiu',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'march-7th-swordmaster',
    char_three: 'Robin',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'Bronya',
    char_three: 'trailblazer-imaginary',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 469,
    avg_round: 3634,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'topaz',
    char_three: 'Sparkle',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Guinaifen',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 469,
    avg_round: 3250,
    star_num: '4'
  },
  {
    char_one: 'Blade',
    char_two: 'march-7th-swordmaster',
    char_three: 'Robin',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 469,
    avg_round: 3188,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Himeko',
    char_three: 'Jiaoqiu',
    char_four: 'Pela',
    app_rate: 0.02,
    rank: 469,
    avg_round: 3537,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'silver-wolf',
    char_three: 'Sparkle',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 469,
    avg_round: 3242,
    star_num: '4'
  },
  {
    char_one: 'Boothill',
    char_two: 'Bronya',
    char_three: 'silver-wolf',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'topaz',
    char_three: 'Sparkle',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Robin',
    char_three: 'silver-wolf',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Himeko',
    char_three: 'Tingyun',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'topaz',
    char_three: 'ruan-mei',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'silver-wolf',
    char_four: 'Gepard',
    app_rate: 0.02,
    rank: 469,
    avg_round: 3254,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'silver-wolf',
    char_four: 'Bailu',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Welt',
    char_three: 'silver-wolf',
    char_four: 'Sparkle',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'Boothill',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'march-7th-swordmaster',
    char_three: 'Tingyun',
    char_four: 'Lingsha',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Blade',
    char_two: 'march-7th-swordmaster',
    char_three: 'Bronya',
    char_four: 'Robin',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'ruan-mei',
    char_three: 'Sparkle',
    char_four: 'Tingyun',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'trailblazer-imaginary',
    char_three: 'ruan-mei',
    char_four: 'Luocha',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'Bronya',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Himeko',
    char_three: 'topaz',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Jiaoqiu',
    char_three: 'Robin',
    char_four: 'Luocha',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Jiaoqiu',
    char_four: 'silver-wolf',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Boothill',
    char_three: 'march-7th-swordmaster',
    char_four: 'Luocha',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'Jade',
    char_three: 'Jiaoqiu',
    char_four: 'Aventurine',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Guinaifen',
    char_two: 'trailblazer-imaginary',
    char_three: 'Jiaoqiu',
    char_four: 'Gallagher',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'Jiaoqiu',
    char_three: 'Robin',
    char_four: 'Luocha',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'march-7th-swordmaster',
    char_two: 'trailblazer-imaginary',
    char_three: 'ruan-mei',
    char_four: 'Luocha',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Blade',
    char_three: 'Robin',
    char_four: 'Luocha',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'topaz',
    char_three: 'Jiaoqiu',
    char_four: 'Robin',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Blade',
    char_two: 'Bronya',
    char_three: 'Jiaoqiu',
    char_four: 'Lingsha',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'ruan-mei',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'silver-wolf',
    char_three: 'Sparkle',
    char_four: 'Huohuo',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'march-7th-swordmaster',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Bronya',
    char_four: 'Gallagher',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'Bronya',
    char_three: 'ruan-mei',
    char_four: 'Huohuo',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'Serval',
    char_three: 'ruan-mei',
    char_four: 'Lingsha',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Hook',
    char_two: 'march-7th-swordmaster',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Jiaoqiu',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'trailblazer-imaginary',
    char_three: 'ruan-mei',
    char_four: 'silver-wolf',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Clara',
    char_three: 'topaz',
    char_four: 'Bailu',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Herta',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'Tingyun',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Qingque',
    char_two: 'ruan-mei',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'march-7th-swordmaster',
    char_three: 'trailblazer-imaginary',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Boothill',
    char_three: 'Robin',
    char_four: 'Gallagher',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Feixiao',
    char_three: 'Robin',
    char_four: 'ruan-mei',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Robin',
    char_three: 'silver-wolf',
    char_four: 'Aventurine',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'march-7th-swordmaster',
    char_three: 'ruan-mei',
    char_four: 'Lingsha',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'imbibitor-lunae',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'Lingsha',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Acheron',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Guinaifen',
    char_four: 'trailblazer-fire',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'trailblazer-imaginary',
    char_three: 'Sparkle',
    char_four: 'Lingsha',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'jing-yuan',
    char_three: 'trailblazer-imaginary',
    char_four: 'Lingsha',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'Guinaifen',
    char_three: 'trailblazer-imaginary',
    char_four: 'Lingsha',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Himeko',
    char_three: 'Kafka',
    char_four: 'ruan-mei',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'silver-wolf',
    char_four: 'Bailu',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'black-swan',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Guinaifen',
    char_four: 'Huohuo',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'topaz',
    char_two: 'Robin',
    char_three: 'ruan-mei',
    char_four: 'Aventurine',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Yunli',
    char_three: 'ruan-mei',
    char_four: 'Luocha',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Yunli',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Xueyi',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'silver-wolf',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'Jiaoqiu',
    char_four: 'Huohuo',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Bronya',
    char_three: 'Jiaoqiu',
    char_four: 'Gallagher',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Boothill',
    char_two: 'Acheron',
    char_three: 'Guinaifen',
    char_four: 'ruan-mei',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Kafka',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Kafka',
    char_three: 'ruan-mei',
    char_four: 'Huohuo',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'dr-ratio',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Asta',
    char_three: 'Jiaoqiu',
    char_four: 'ruan-mei',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Blade',
    char_two: 'march-7th-swordmaster',
    char_three: 'Sparkle',
    char_four: 'Huohuo',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'silver-wolf',
    char_four: 'Huohuo',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'ruan-mei',
    char_three: 'Tingyun',
    char_four: 'Lingsha',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'topaz',
    char_three: 'ruan-mei',
    char_four: 'Luocha',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Serval',
    char_two: 'Robin',
    char_three: 'Tingyun',
    char_four: 'Gallagher',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'Acheron',
    char_three: 'silver-wolf',
    char_four: 'Gallagher',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'Jade',
    char_three: 'Himeko',
    char_four: 'ruan-mei',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'ruan-mei',
    char_four: 'Luocha',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Jade',
    char_three: 'imbibitor-lunae',
    char_four: 'Huohuo',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Welt',
    char_three: 'Guinaifen',
    char_four: 'trailblazer-fire',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Huohuo',
    char_four: 'Gepard',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Guinaifen',
    char_two: 'trailblazer-imaginary',
    char_three: 'Jiaoqiu',
    char_four: 'ruan-mei',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'march-7th-swordmaster',
    char_three: 'Robin',
    char_four: 'Lingsha',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'Guinaifen',
    char_three: 'ruan-mei',
    char_four: 'Huohuo',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Jade',
    char_three: 'Sparkle',
    char_four: 'Lingsha',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'ruan-mei',
    char_four: 'Aventurine',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Jade',
    char_three: 'Moze',
    char_four: 'Luocha',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'Hanya',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Guinaifen',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Guinaifen',
    char_three: 'Bronya',
    char_four: 'ruan-mei',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'silver-wolf',
    char_four: 'Lingsha',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'Robin',
    char_three: 'Yukong',
    char_four: 'Huohuo',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Welt',
    char_three: 'Sparkle',
    char_four: 'Lingsha',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'Bronya',
    char_three: 'ruan-mei',
    char_four: 'Sparkle',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'Pela',
    char_three: 'Sparkle',
    char_four: 'Luocha',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'Moze',
    char_three: 'Robin',
    char_four: 'Bailu',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'Yunli',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Himeko',
    char_three: 'Kafka',
    char_four: 'ruan-mei',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'topaz',
    char_three: 'Sparkle',
    char_four: 'Huohuo',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'Jiaoqiu',
    char_three: 'ruan-mei',
    char_four: 'Luocha',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'march-7th-swordmaster',
    char_two: 'Bronya',
    char_three: 'Robin',
    char_four: 'Lingsha',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Feixiao',
    char_three: 'Robin',
    char_four: 'Luocha',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Welt',
    char_three: 'ruan-mei',
    char_four: 'Lingsha',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Acheron',
    char_three: 'Jiaoqiu',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'Gallagher',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'ruan-mei',
    char_four: 'Lingsha',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'Hanya',
    char_three: 'Tingyun',
    char_four: 'Gallagher',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'march-7th-swordmaster',
    char_two: 'Robin',
    char_three: 'ruan-mei',
    char_four: 'Aventurine',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Robin',
    char_three: 'Tingyun',
    char_four: 'Gallagher',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'Asta',
    char_three: 'Sparkle',
    char_four: 'Lingsha',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Boothill',
    char_two: 'Bronya',
    char_three: 'trailblazer-imaginary',
    char_four: 'Huohuo',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'Himeko',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'black-swan',
    char_four: 'Natasha',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'ruan-mei',
    char_three: 'Sparkle',
    char_four: 'Gallagher',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Welt',
    char_three: 'ruan-mei',
    char_four: 'Sparkle',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Qingque',
    char_two: 'silver-wolf',
    char_three: 'Sparkle',
    char_four: 'Huohuo',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Bronya',
    char_four: 'Gepard',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'Himeko',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Welt',
    char_three: 'Guinaifen',
    char_four: 'Luocha',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'Bronya',
    char_three: 'trailblazer-imaginary',
    char_four: 'Lynx',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'topaz',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'topaz',
    char_two: 'silver-wolf',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'trailblazer-imaginary',
    char_three: 'Robin',
    char_four: 'Lingsha',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'ruan-mei',
    char_three: 'silver-wolf',
    char_four: 'Luocha',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Jiaoqiu',
    char_four: 'Lynx',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Guinaifen',
    char_three: 'Jiaoqiu',
    char_four: 'Gepard',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Boothill',
    char_two: 'march-7th-swordmaster',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Robin',
    char_four: 'Gallagher',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'ruan-mei',
    char_three: 'Tingyun',
    char_four: 'Gallagher',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'Serval',
    char_three: 'ruan-mei',
    char_four: 'Huohuo',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Kafka',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'Jiaoqiu',
    char_three: 'ruan-mei',
    char_four: 'Lingsha',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Guinaifen',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'ruan-mei',
    char_three: 'Tingyun',
    char_four: 'Gallagher',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'trailblazer-imaginary',
    char_three: 'ruan-mei',
    char_four: 'Aventurine',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'Robin',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Kafka',
    char_three: 'Jiaoqiu',
    char_four: 'Luocha',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'dan-heng',
    char_three: 'Robin',
    char_four: 'Gallagher',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'Himeko',
    char_three: 'ruan-mei',
    char_four: 'Sparkle',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'ruan-mei',
    char_three: 'Tingyun',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'black-swan',
    char_three: 'Robin',
    char_four: 'Luocha',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Asta',
    char_four: 'Gallagher',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Guinaifen',
    char_four: 'Luocha',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'silver-wolf',
    char_four: 'Aventurine',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Sushang',
    char_two: 'march-7th-swordmaster',
    char_three: 'Robin',
    char_four: 'Lingsha',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'march-7th-swordmaster',
    char_three: 'Moze',
    char_four: 'Aventurine',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Jiaoqiu',
    char_four: 'Lingsha',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Blade',
    char_two: 'trailblazer-imaginary',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'Bronya',
    char_three: 'Robin',
    char_four: 'Luocha',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'dr-ratio',
    char_three: 'topaz',
    char_four: 'Lingsha',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'topaz',
    char_three: 'Aventurine',
    char_four: 'Lingsha',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'Acheron',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Clara',
    char_three: 'Sparkle',
    char_four: 'Huohuo',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'silver-wolf',
    char_four: 'Sparkle',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Bronya',
    char_two: 'ruan-mei',
    char_three: 'Sparkle',
    char_four: 'Lingsha',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'march-7th-swordmaster',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'ruan-mei',
    char_four: 'Lingsha',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Bronya',
    char_three: 'Robin',
    char_four: 'Gallagher',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Moze',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'silver-wolf',
    char_four: 'Gepard',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Guinaifen',
    char_three: 'Sparkle',
    char_four: 'Huohuo',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'march-7th-swordmaster',
    char_three: 'Sparkle',
    char_four: 'Lingsha',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Himeko',
    char_three: 'Welt',
    char_four: 'Sparkle',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'Pela',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'Sparkle',
    char_three: 'Yukong',
    char_four: 'Luocha',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Luka',
    char_four: 'Luocha',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Xueyi',
    char_three: 'Sparkle',
    char_four: 'Gallagher',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Asta',
    char_three: 'ruan-mei',
    char_four: 'Aventurine',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Boothill',
    char_two: 'Acheron',
    char_three: 'Jiaoqiu',
    char_four: 'Huohuo',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'ruan-mei',
    char_four: 'Gepard',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Acheron',
    char_three: 'Himeko',
    char_four: 'Gallagher',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Jade',
    char_three: 'Bronya',
    char_four: 'Huohuo',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Guinaifen',
    char_four: 'Jiaoqiu',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Himeko',
    char_three: 'ruan-mei',
    char_four: 'Lynx',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Boothill',
    char_two: 'Clara',
    char_three: 'Bronya',
    char_four: 'Lingsha',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Clara',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'Robin',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Firefly',
    char_three: 'trailblazer-imaginary',
    char_four: 'Gallagher',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Bronya',
    char_three: 'Tingyun',
    char_four: 'Gallagher',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'march-7th-swordmaster',
    char_three: 'Bronya',
    char_four: 'Robin',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Welt',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'trailblazer-imaginary',
    char_three: 'ruan-mei',
    char_four: 'Luocha',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'Robin',
    char_three: 'silver-wolf',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'black-swan',
    char_three: 'topaz',
    char_four: 'Aventurine',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Himeko',
    char_three: 'Guinaifen',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'Bronya',
    char_three: 'Pela',
    char_four: 'Aventurine',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'march-7th-swordmaster',
    char_two: 'Moze',
    char_three: 'Robin',
    char_four: 'Lingsha',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Jiaoqiu',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Serval',
    char_three: 'trailblazer-imaginary',
    char_four: 'Huohuo',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'trailblazer-imaginary',
    char_four: 'Jiaoqiu',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'silver-wolf',
    char_three: 'Sparkle',
    char_four: 'Luocha',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Boothill',
    char_two: 'march-7th-swordmaster',
    char_three: 'Bronya',
    char_four: 'Huohuo',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'topaz',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'Jiaoqiu',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Boothill',
    char_two: 'march-7th-swordmaster',
    char_three: 'trailblazer-imaginary',
    char_four: 'Aventurine',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'topaz',
    char_three: 'Sparkle',
    char_four: 'Lingsha',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'ruan-mei',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Yunli',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Clara',
    char_three: 'Bronya',
    char_four: 'Lingsha',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Himeko',
    char_three: 'Guinaifen',
    char_four: 'Gallagher',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Yunli',
    char_three: 'Bronya',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'Himeko',
    char_three: 'Sparkle',
    char_four: 'Luocha',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Welt',
    char_three: 'Sparkle',
    char_four: 'Bailu',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'Acheron',
    char_three: 'silver-wolf',
    char_four: 'Sparkle',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'Robin',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'silver-wolf',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Acheron',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Boothill',
    char_two: 'Bronya',
    char_three: 'Huohuo',
    char_four: 'Gallagher',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Acheron',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Clara',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Robin',
    char_three: 'ruan-mei',
    char_four: 'Lingsha',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'topaz',
    char_three: 'Jiaoqiu',
    char_four: 'Luocha',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'Blade',
    char_three: 'Hanya',
    char_four: 'Luocha',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'Boothill',
    char_three: 'Jiaoqiu',
    char_four: 'Aventurine',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'ruan-mei',
    char_three: 'silver-wolf',
    char_four: 'trailblazer-fire',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'march-7th-swordmaster',
    char_three: 'topaz',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Welt',
    char_three: 'Jiaoqiu',
    char_four: 'Sparkle',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'Firefly',
    char_three: 'trailblazer-imaginary',
    char_four: 'Luocha',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Jade',
    char_three: 'Tingyun',
    char_four: 'Aventurine',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Guinaifen',
    char_three: 'ruan-mei',
    char_four: 'silver-wolf',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Guinaifen',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'topaz',
    char_three: 'trailblazer-imaginary',
    char_four: 'Gallagher',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Sushang',
    char_two: 'march-7th-swordmaster',
    char_three: 'Sparkle',
    char_four: 'Huohuo',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'ruan-mei',
    char_three: 'silver-wolf',
    char_four: 'Lingsha',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Blade',
    char_three: 'Robin',
    char_four: 'Luocha',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'trailblazer-imaginary',
    char_three: 'Jiaoqiu',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Acheron',
    char_three: 'Kafka',
    char_four: 'ruan-mei',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'black-swan',
    char_three: 'Tingyun',
    char_four: 'Luocha',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'topaz',
    char_three: 'Asta',
    char_four: 'Aventurine',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Sampo',
    char_four: 'Luocha',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Robin',
    char_three: 'silver-wolf',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'topaz',
    char_four: 'Lynx',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Himeko',
    char_three: 'silver-wolf',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Acheron',
    char_three: 'Bronya',
    char_four: 'Jiaoqiu',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'ruan-mei',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'Pela',
    char_three: 'ruan-mei',
    char_four: 'Aventurine',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Welt',
    char_three: 'Guinaifen',
    char_four: 'ruan-mei',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Guinaifen',
    char_four: 'Huohuo',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'Jiaoqiu',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Acheron',
    char_three: 'Kafka',
    char_four: 'Gepard',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'dr-ratio',
    char_three: 'topaz',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Bronya',
    char_three: 'Sparkle',
    char_four: 'Luocha',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'march-7th-swordmaster',
    char_three: 'ruan-mei',
    char_four: 'Huohuo',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'Sparkle',
    char_three: 'Huohuo',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'trailblazer-imaginary',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'Tingyun',
    char_four: 'Aventurine',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Himeko',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Seele',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'Acheron',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'Blade',
    char_three: 'Robin',
    char_four: 'Luocha',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'Guinaifen',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'trailblazer-imaginary',
    char_three: 'Robin',
    char_four: 'Lingsha',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Pela',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'Robin',
    char_three: 'ruan-mei',
    char_four: 'Luocha',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'topaz',
    char_three: 'Hanya',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Himeko',
    char_three: 'Welt',
    char_four: 'Gallagher',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Asta',
    char_three: 'Robin',
    char_four: 'Gallagher',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Blade',
    char_two: 'Bronya',
    char_three: 'Sparkle',
    char_four: 'Natasha',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'march-7th-swordmaster',
    char_two: 'topaz',
    char_three: 'Sparkle',
    char_four: 'Lingsha',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Blade',
    char_three: 'Jiaoqiu',
    char_four: 'Huohuo',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'Bronya',
    char_three: 'ruan-mei',
    char_four: 'Aventurine',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Guinaifen',
    char_three: 'ruan-mei',
    char_four: 'Lingsha',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'topaz',
    char_three: 'Luocha',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Jiaoqiu',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'Pela',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'silver-wolf',
    char_three: 'Sparkle',
    char_four: 'Gallagher',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'Jiaoqiu',
    char_three: 'ruan-mei',
    char_four: 'Huohuo',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Feixiao',
    char_three: 'ruan-mei',
    char_four: 'Aventurine',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Blade',
    char_three: 'Sparkle',
    char_four: 'Luocha',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Acheron',
    char_three: 'Himeko',
    char_four: 'Bailu',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Asta',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'march-7th-swordmaster',
    char_two: 'Bronya',
    char_three: 'Robin',
    char_four: 'Luocha',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Acheron',
    char_three: 'black-swan',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Robin',
    char_four: 'Lynx',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'trailblazer-imaginary',
    char_three: 'Jiaoqiu',
    char_four: 'Lingsha',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Jiaoqiu',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Yunli',
    char_three: 'Tingyun',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'trailblazer-imaginary',
    char_three: 'Sparkle',
    char_four: 'Gallagher',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'Himeko',
    char_three: 'Serval',
    char_four: 'trailblazer-imaginary',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'Yunli',
    char_three: 'Robin',
    char_four: 'Luocha',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'Robin',
    char_three: 'Gallagher',
    char_four: 'Aventurine',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'march-7th-swordmaster',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'Bronya',
    char_three: 'Sparkle',
    char_four: 'Gallagher',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Acheron',
    char_three: 'Jiaoqiu',
    char_four: 'Huohuo',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'topaz',
    char_three: 'ruan-mei',
    char_four: 'Lingsha',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Jiaoqiu',
    char_three: 'Sparkle',
    char_four: 'Huohuo',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Boothill',
    char_two: 'march-7th-swordmaster',
    char_three: 'Bronya',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Guinaifen',
    char_three: 'ruan-mei',
    char_four: 'Aventurine',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'Firefly',
    char_three: 'trailblazer-imaginary',
    char_four: 'Tingyun',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Feixiao',
    char_three: 'Bronya',
    char_four: 'Aventurine',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Sushang',
    char_two: 'trailblazer-imaginary',
    char_three: 'ruan-mei',
    char_four: 'Aventurine',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Kafka',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Acheron',
    char_three: 'Jiaoqiu',
    char_four: 'Gallagher',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'trailblazer-imaginary',
    char_three: 'silver-wolf',
    char_four: 'Gallagher',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Boothill',
    char_three: 'ruan-mei',
    char_four: 'Aventurine',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Pela',
    char_four: 'Lingsha',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Acheron',
    char_three: 'Jiaoqiu',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Bronya',
    char_three: 'Tingyun',
    char_four: 'Natasha',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'march-7th-swordmaster',
    char_three: 'Robin',
    char_four: 'Lynx',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Bronya',
    char_two: 'trailblazer-imaginary',
    char_three: 'ruan-mei',
    char_four: 'Lingsha',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'Xueyi',
    char_three: 'trailblazer-imaginary',
    char_four: 'Gallagher',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'Robin',
    char_three: 'Tingyun',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Feixiao',
    char_three: 'march-7th-swordmaster',
    char_four: 'Aventurine',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'Robin',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'march-7th-swordmaster',
    char_three: 'Asta',
    char_four: 'Huohuo',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Acheron',
    char_three: 'Bronya',
    char_four: 'Gallagher',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Sushang',
    char_two: 'Robin',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'topaz',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'Acheron',
    char_three: 'Kafka',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Asta',
    char_three: 'Jiaoqiu',
    char_four: 'Luocha',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'Robin',
    char_three: 'ruan-mei',
    char_four: 'Lingsha',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Himeko',
    char_three: 'ruan-mei',
    char_four: 'Lingsha',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Jiaoqiu',
    char_three: 'Tingyun',
    char_four: 'Gallagher',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'topaz',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'trailblazer-imaginary',
    char_three: 'Jiaoqiu',
    char_four: 'Gallagher',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Hook',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'Lingsha',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Natasha',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Serval',
    char_three: 'Sparkle',
    char_four: 'Lingsha',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Jade',
    char_three: 'Sparkle',
    char_four: 'Lynx',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'Acheron',
    char_three: 'trailblazer-imaginary',
    char_four: 'ruan-mei',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'imbibitor-lunae',
    char_three: 'ruan-mei',
    char_four: 'Luocha',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'march-7th-swordmaster',
    char_two: 'topaz',
    char_three: 'ruan-mei',
    char_four: 'Aventurine',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Firefly',
    char_three: 'trailblazer-imaginary',
    char_four: 'ruan-mei',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Serval',
    char_three: 'Sparkle',
    char_four: 'Lynx',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'march-7th',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Welt',
    char_two: 'Jiaoqiu',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'march-7th-swordmaster',
    char_two: 'topaz',
    char_three: 'ruan-mei',
    char_four: 'Lingsha',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Xueyi',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'Bronya',
    char_three: 'Robin',
    char_four: 'silver-wolf',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'trailblazer-imaginary',
    char_three: 'ruan-mei',
    char_four: 'Tingyun',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'march-7th-swordmaster',
    char_two: 'topaz',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'dr-ratio',
    char_three: 'topaz',
    char_four: 'Robin',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'Bronya',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Welt',
    char_three: 'Bronya',
    char_four: 'Gallagher',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'black-swan',
    char_three: 'Robin',
    char_four: 'Luocha',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Robin',
    char_four: 'Gepard',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Feixiao',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Clara',
    char_three: 'black-swan',
    char_four: 'Luocha',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'march-7th-swordmaster',
    char_three: 'ruan-mei',
    char_four: 'Lingsha',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'march-7th-swordmaster',
    char_three: 'trailblazer-imaginary',
    char_four: 'ruan-mei',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'march-7th-swordmaster',
    char_three: 'Robin',
    char_four: 'Gallagher',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'Feixiao',
    char_three: 'trailblazer-imaginary',
    char_four: 'Luocha',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'march-7th-swordmaster',
    char_three: 'Tingyun',
    char_four: 'Lingsha',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'Robin',
    char_three: 'ruan-mei',
    char_four: 'Huohuo',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Boothill',
    char_two: 'Clara',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'silver-wolf',
    char_four: 'Bailu',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Seele',
    char_two: 'Robin',
    char_three: 'silver-wolf',
    char_four: 'Huohuo',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Pela',
    char_four: 'Lynx',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Moze',
    char_three: 'topaz',
    char_four: 'Lynx',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Sparkle',
    char_four: 'Lynx',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'Bronya',
    char_three: 'silver-wolf',
    char_four: 'Aventurine',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'Yunli',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'Acheron',
    char_three: 'Kafka',
    char_four: 'Luocha',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'trailblazer-imaginary',
    char_three: 'Robin',
    char_four: 'ruan-mei',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'ruan-mei',
    char_three: 'silver-wolf',
    char_four: 'Lynx',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Jiaoqiu',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'march-7th-swordmaster',
    char_three: 'Jiaoqiu',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Blade',
    char_three: 'Robin',
    char_four: 'Gallagher',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'Blade',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Welt',
    char_two: 'Bronya',
    char_three: 'Robin',
    char_four: 'Gallagher',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'Himeko',
    char_three: 'Bronya',
    char_four: 'Sparkle',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'Bronya',
    char_three: 'Pela',
    char_four: 'Aventurine',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'trailblazer-imaginary',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Robin',
    char_three: 'silver-wolf',
    char_four: 'Gepard',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'Asta',
    char_three: 'Jiaoqiu',
    char_four: 'Lingsha',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'Acheron',
    char_three: 'Kafka',
    char_four: 'Gallagher',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Robin',
    char_three: 'fu-xuan',
    char_four: 'Lingsha',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Bronya',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Himeko',
    char_three: 'Tingyun',
    char_four: 'Lingsha',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'Jiaoqiu',
    char_four: 'Pela',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Welt',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'Bronya',
    char_three: 'Robin',
    char_four: 'Lingsha',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Jingliu',
    char_three: 'ruan-mei',
    char_four: 'Lingsha',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'Gallagher',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'silver-wolf',
    char_four: 'Sparkle',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'Yunli',
    char_three: 'ruan-mei',
    char_four: 'Aventurine',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'Jade',
    char_three: 'Asta',
    char_four: 'trailblazer-imaginary',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Blade',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Acheron',
    char_three: 'Kafka',
    char_four: 'Gallagher',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Welt',
    char_three: 'silver-wolf',
    char_four: 'Gallagher',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'Boothill',
    char_three: 'Sparkle',
    char_four: 'Huohuo',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'topaz',
    char_two: 'Sampo',
    char_three: 'Jiaoqiu',
    char_four: 'Aventurine',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Guinaifen',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'silver-wolf',
    char_three: 'Sparkle',
    char_four: 'Bailu',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'Pela',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'ruan-mei',
    char_three: 'Sparkle',
    char_four: 'Huohuo',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'Moze',
    char_three: 'Tingyun',
    char_four: 'Aventurine',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Bronya',
    char_four: 'Aventurine',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'topaz',
    char_three: 'Yukong',
    char_four: 'Aventurine',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Acheron',
    char_three: 'Guinaifen',
    char_four: 'Aventurine',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'imbibitor-lunae',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Boothill',
    char_two: 'Luka',
    char_three: 'Hanya',
    char_four: 'Huohuo',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'Sparkle',
    char_three: 'Luocha',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'march-7th-swordmaster',
    char_three: 'ruan-mei',
    char_four: 'Huohuo',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'trailblazer-imaginary',
    char_three: 'Tingyun',
    char_four: 'Gallagher',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'Himeko',
    char_three: 'march-7th-swordmaster',
    char_four: 'ruan-mei',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Sparkle',
    char_four: 'Huohuo',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Guinaifen',
    char_three: 'Asta',
    char_four: 'Jiaoqiu',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Feixiao',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Bronya',
    char_three: 'Robin',
    char_four: 'Gepard',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Himeko',
    char_three: 'ruan-mei',
    char_four: 'Huohuo',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'trailblazer-imaginary',
    char_three: 'Sparkle',
    char_four: 'Lingsha',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'Bronya',
    char_three: 'trailblazer-imaginary',
    char_four: 'Sparkle',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'ruan-mei',
    char_three: 'Sparkle',
    char_four: 'Lingsha',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Welt',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Jiaoqiu',
    char_three: 'ruan-mei',
    char_four: 'Lingsha',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'silver-wolf',
    char_three: 'Sparkle',
    char_four: 'trailblazer-fire',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'march-7th-swordmaster',
    char_three: 'trailblazer-imaginary',
    char_four: 'Lingsha',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'Bronya',
    char_three: 'trailblazer-imaginary',
    char_four: 'Huohuo',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'silver-wolf',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Feixiao',
    char_three: 'topaz',
    char_four: 'Aventurine',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Xueyi',
    char_two: 'silver-wolf',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Tingyun',
    char_four: 'Luocha',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Kafka',
    char_three: 'black-swan',
    char_four: 'ruan-mei',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'dr-ratio',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Xueyi',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Moze',
    char_three: 'Jiaoqiu',
    char_four: 'Aventurine',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'Hanya',
    char_three: 'Yukong',
    char_four: 'Luocha',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'Pela',
    char_three: 'Sparkle',
    char_four: 'Gallagher',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'topaz',
    char_three: 'ruan-mei',
    char_four: 'Lingsha',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Bronya',
    char_three: 'Robin',
    char_four: 'Lingsha',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'Acheron',
    char_three: 'ruan-mei',
    char_four: 'Luocha',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'march-7th-swordmaster',
    char_three: 'Guinaifen',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'ruan-mei',
    char_four: 'Gepard',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Clara',
    char_three: 'Bronya',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'Jade',
    char_three: 'ruan-mei',
    char_four: 'Lingsha',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Welt',
    char_three: 'trailblazer-imaginary',
    char_four: 'ruan-mei',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Argenti',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Boothill',
    char_two: 'Acheron',
    char_three: 'trailblazer-imaginary',
    char_four: 'ruan-mei',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'Sparkle',
    char_four: 'Bailu',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'topaz',
    char_three: 'ruan-mei',
    char_four: 'Huohuo',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'topaz',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Sampo',
    char_four: 'Huohuo',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'Serval',
    char_three: 'Robin',
    char_four: 'ruan-mei',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Welt',
    char_three: 'Sparkle',
    char_four: 'Luocha',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Robin',
    char_three: 'ruan-mei',
    char_four: 'Lingsha',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Bronya',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Yunli',
    char_three: 'ruan-mei',
    char_four: 'Lingsha',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'trailblazer-imaginary',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Boothill',
    char_three: 'Bronya',
    char_four: 'Aventurine',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Jade',
    char_three: 'Robin',
    char_four: 'Luocha',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'trailblazer-imaginary',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Sparkle',
    char_four: 'Lingsha',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Boothill',
    char_two: 'Bronya',
    char_three: 'ruan-mei',
    char_four: 'Bailu',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Feixiao',
    char_three: 'Robin',
    char_four: 'Gallagher',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'ruan-mei',
    char_four: 'Huohuo',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'Moze',
    char_three: 'ruan-mei',
    char_four: 'Aventurine',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Blade',
    char_three: 'ruan-mei',
    char_four: 'Huohuo',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'ruan-mei',
    char_four: 'Sparkle',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Asta',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'ruan-mei',
    char_three: 'Yukong',
    char_four: 'Aventurine',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Blade',
    char_three: 'Bronya',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'march-7th-swordmaster',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Lingsha',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'march-7th-swordmaster',
    char_three: 'Asta',
    char_four: 'Gallagher',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'Himeko',
    char_three: 'ruan-mei',
    char_four: 'Lingsha',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Himeko',
    char_three: 'Jiaoqiu',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'march-7th-swordmaster',
    char_four: 'Lingsha',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Yunli',
    char_three: 'Tingyun',
    char_four: 'Lynx',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Himeko',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'Jade',
    char_three: 'ruan-mei',
    char_four: 'Huohuo',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Xueyi',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Guinaifen',
    char_four: 'Bailu',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'Yunli',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'topaz',
    char_three: 'ruan-mei',
    char_four: 'Luocha',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Guinaifen',
    char_three: 'ruan-mei',
    char_four: 'Luocha',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Seele',
    char_two: 'ruan-mei',
    char_three: 'silver-wolf',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'Hanya',
    char_three: 'Tingyun',
    char_four: 'Aventurine',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'Luocha',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'black-swan',
    char_three: 'Robin',
    char_four: 'Luocha',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'march-7th-swordmaster',
    char_two: 'topaz',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Acheron',
    char_three: 'Robin',
    char_four: 'trailblazer-fire',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Feixiao',
    char_three: 'march-7th-swordmaster',
    char_four: 'Luocha',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Jiaoqiu',
    char_four: 'Pela',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Acheron',
    char_three: 'Jiaoqiu',
    char_four: 'Aventurine',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'Bronya',
    char_three: 'ruan-mei',
    char_four: 'Lingsha',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Qingque',
    char_two: 'Bronya',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Feixiao',
    char_three: 'Bronya',
    char_four: 'Luocha',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'Asta',
    char_three: 'trailblazer-imaginary',
    char_four: 'Huohuo',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Qingque',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'Lingsha',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'silver-wolf',
    char_three: 'Sparkle',
    char_four: 'Huohuo',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'Jiaoqiu',
    char_three: 'ruan-mei',
    char_four: 'Aventurine',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'Lingsha',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'march-7th-swordmaster',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'black-swan',
    char_three: 'Sparkle',
    char_four: 'Gallagher',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Blade',
    char_two: 'Bronya',
    char_three: 'Sparkle',
    char_four: 'Lingsha',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'Robin',
    char_four: 'Tingyun',
    app_rate: 0.01,
    rank: 665,
    avg_round: 0,
    star_num: '4'
  }
];
